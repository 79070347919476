<template>
  <div class="main typeArea">
    <div class="main-left">
      <div
        class="item"
        @click="depthOfTheArticleDetails(item.id)"
        v-for="item in depthArticlelist"
        :key="item.id"
      >
        <div class="item-left">
          <img
            :src="item.imgsrc"
            alt=""
          >
        </div>
        <div class="item-right">
          <div class="right-top">
            <div class="top-title">
              {{item.post_title}}
            </div>
            <div
              class="top-content"
              v-html="item.post_excerpt"
            >
            </div>
          </div>
          <div class="right-bootom">
            <div class="bottom-tag">
              <div
                class="bottom-tag-item"
                v-for="(ite,index) in item.tagsArray"
                :key="index"
              >{{ite}}</div>
            </div>
            <div class="time">
              {{item.showTimeText}}
            </div>

          </div>

        </div>
      </div>
      <div
        class="seemore"
        @click="getMoreNews"
      >
        查看更多内容
      </div>
    </div>
    <div class="main-right">
      <div class="read-ranking">
        <div class="read-title">
          <div class="shuxian"></div>
          <div class="title-text">
            阅读排行
          </div>
        </div>
        <div class="read-content">
          <div
            class="content-item"
            v-for="item1 in readingList.slice(0,5)"
            :key="item1.id"
          >
            <div class="dian"></div>
            <div
              class="item-text"
              @click="depthOfTheArticleDetails(item1.id)"
            >
              <div class="text-top">
                {{item1.post_title}}
              </div>
              <div class="text-time">
                {{item1.showTimeText1}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { getDepthArticlelist, articleorder } from '../../../api/depthOfTheArticle'
import { formatDate1 } from '../../../util/tools'
import { Addhistoricalrecordwz } from '../../../api/myRecentInformation'
export default {
  data () {
    return {
      // 深度文章列表
      depthArticlelist: [],
      // 当前页数
      currentPage: '1',
      // 阅读排行数组
      readingList: []
    }
  },
  created () {
    // 获取初始化数据
    this.getData()
    this.navxuanzhong()
  },
  methods: {
    navxuanzhong(){
      localStorage.setItem("navActive", 5);
      this.$store.commit("setnavActive", 5);
    },
    showNews: function () {
      this.$router.push({
        path: 'depthOfTheArticleDetails',
        query: '123'
      })
    },
    // 数据按照字段名降序排序
    sortDesByKey (ary, key) {
      return ary.sort((a, b) => {
        const x = a[key]
        const y = b[key]
        return ((x < y) ? 1 : (x > y) ? -1 : 0)
      })
    },
    // 时间转换成显示时间
    getTimeToShow(pushTime) {
      const nowtime = new Date();
      const time = nowtime - parseInt(pushTime * 1000);
      if (time < 60000) {
        return "刚刚";
      } else if (time > 60000 && time < 3600000) {
        return Math.floor(time / 60000) + "分钟前";
      } else if (time > 3600000 && time < 86400000) {
        return Math.floor(time / 3600000) + "小时前";
      }else if (time > 86400000 && time < 2592000000) {
        return Math.floor(time / 86400000) + '天前'
      }else if (time > 2592000000 && time < 31104000000) {
        return Math.floor(time / 2592000000) + '月前'
      }else{
        return Math.floor(time / 31104000000) + '年前'
      }
    },
getTimeToShow1 (pushTime) {
      const nowtime = Date.parse(new Date())
      const time = nowtime - pushTime * 1000
      if (time < 60000) {
        return '刚刚'
      } else if (time > 60000 && time < 3600000) {
        return Math.floor(time / 60000) + '分钟前'
      } else if (time > 3600000 && time < 86400000) {
        return Math.floor(time / 3600000) + '小时前'
      } else if (time > 86400000 && time < 604800000) {
        return Math.floor(time / 86400000) + '天前'
      } else {
        return formatDate1(pushTime)
      }
    },
    // 返回数据处理
    processingReturnData (arr) {
      arr.forEach(elem => {
        elem.imgsrc = `https://keyanpro.com/upload/${elem.thumbnail}`
        elem.tagsArray = elem.post_keywords.split(';')
        elem.showTimeText = this.getTimeToShow(elem.published_time)
        elem.showTimeText1 = this.getTimeToShow1(elem.published_time)
      })
      return arr
    },
    // 数据获取
    getData () {
      const that = this
      // 获取深度文章
      getDepthArticlelist({ limit: 10, page: this.$data.currentPage }).then(res => {
        console.log(res.data.tagsArray)
        res.data = this.processingReturnData(res.data)
        console.log('数据处理', this.processingReturnData(res.data))
        that.$data.depthArticlelist = res.data
        console.log('文章列表', that.$data.depthArticlelist)
      }, err => console.log(err))
      // 获取深度文章排行数组
      articleorder({ limit: 12, page: 1 }).then(res => {
        console.log(res.data.tagsArray)
        res.data = this.processingReturnData(res.data)
        console.log('数据处理', this.processingReturnData(res.data))
        that.$data.readingList = that.sortDesByKey(res.data, 'is_top')
        console.log('文章列表', that.$data.depthArticlelist)
      }, err => console.log(err))
    },
    // 跳转到更多文章详情页
    depthOfTheArticleDetails (id) {
      localStorage.setItem('navActive', 5)
      this.$store.commit('setnavActive', 5)
      const tid = id
      const uid = this.$store.state.userid
      const params = {
        title_id: tid,
        user_id: uid
        // type: 1
      }
      Addhistoricalrecordwz(params).then((res) => {
        console.log('添加历史记录成功', res)
      }, (err) => {
        console.log('添加历史记录异常', err)
      })

      this.$router.push({
        path: `depthOfTheArticleDetails${id}`
      })
      window._czc.push(['_trackEvent', '点击', '跳转到更多文章详情页'])
    },
    // 点击查看跟多
    getMoreNews () {
      const that = this
      this.currentPage = parseInt(this.currentPage) + 1
      const pagenum = this.currentPage
      console.log('pagenum', pagenum)
      getDepthArticlelist({ limit: 10, page: pagenum }).then(res => {
        console.log('加载更多接口返回数据', res)
        res.data = this.processingReturnData(res.data)
        console.log('数据处理', this.processingReturnData(res.data))
        that.$data.depthArticlelist = that.$data.depthArticlelist.concat(res.data)
        console.log('文章列表', that.$data.depthArticlelist)
      }, err => console.log(err))
      window._czc.push(['_trackEvent', '点击', '更多文章查看更多'])
    }
  }

}
</script>

<style lang="scss" scoped>
// 版心
.typeArea {
  width: 1200px;
  margin: 0 auto;
}
.main {
  margin-top: 51px;
  display: flex;
  .main-left {
    width: 832px;
    display: flex;
    flex-direction: column;
    .item {
      height: 153px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 49px;
      .item-left {
        cursor: pointer;
        margin-left: 18px;
        margin-right: 20px;
        img {
          height: 153px;
          width: 287px;
          object-fit: cover;
        }
      }
      .item-right {
        cursor: pointer;
        width: 524.05px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .right-top {
          .top-title {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #282828;
            margin-bottom: 13px;
          }
          .top-content {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 20px;
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #9d9d9d;
          }
        }
        .right-bootom {
          display: flex;
          justify-content: space-between;
          .bottom-tag {
            display: flex;
            justify-content: left;
            align-items: center;
            .bottom-tag-item {
              height: 22px;
              background: #faf7fb;
              font-size: 10px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 22px;
              color: #5e068c;
              margin-right: 6px;
              padding: 0 5px;
              border-radius: 3px;
            }
          }
          .time {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 22px;
            color: #b9b9b9;
          }
        }
      }
    }
    .seemore {
      cursor: pointer;
      margin: 0 auto;
      width: 180px;
      height: 36px;
      border: 1px solid #b9b9b9;
      border-radius: 4px;
      margin-bottom: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #b9b9b9;
    }
  }
  .main-right {
    position: relative;
    flex: 1;
    .read-ranking {
      width: 320px;
      border: 1px solid #f1f2f6;
      position: absolute;
      top: -25px;
      right: 0px;
      padding: 31px 26px;
      box-sizing: border-box;
      .read-title {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 22px;
        .shuxian {
          width: 4px;
          height: 12px;
          border-radius: 2px;
          background-color: #5e068c;
          margin-right: 3px;
        }
        .title-text {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
      .read-content {
        display: flex;
        flex-direction: column;
        .content-item {
          margin-bottom: 22px;
          display: flex;
          .dian {
            width: 6px;
            height: 6px;
            box-sizing: border-box;
            margin-right: 5px;
            border-radius: 50%;
            background-color: #5e068c;
            transform: translate(0px, 6px);
          }
          .item-text {
            flex: 1;
            cursor: pointer;
            widows: 257px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .text-top {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 18px;
            color: #282828;
            margin-bottom: 11px;
          }
          .text-time {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #717171;
          }
        }
      }
    }
  }
}
</style>
