import { render, staticRenderFns } from "./depthOfTheArticle.vue?vue&type=template&id=65e59ad3&scoped=true&"
import script from "./depthOfTheArticle.vue?vue&type=script&lang=js&"
export * from "./depthOfTheArticle.vue?vue&type=script&lang=js&"
import style0 from "./depthOfTheArticle.vue?vue&type=style&index=0&id=65e59ad3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e59ad3",
  null
  
)

export default component.exports