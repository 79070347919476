
import request from '../util/request'

// 1.获取深度文章列表
export const getDepthArticlelist = (params) => {
  return request({
    url: '/api/demo/Articles/list',
    method: 'post',
    data: {
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }

  })
}
// 深度文章阅读排行
export const articleorder = (params) => {
  return request({
    url: 'api/demo/Articles/paihang',
    method: 'post',
    data: {
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || '',
      id: params.id
    }

  })
}
